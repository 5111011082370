// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './util/http.js'
// file-path
import util from './util/util.js'
Vue.prototype.alOssUrl = 'https://scjyfile.zhsjgl.com/'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.alOssUrl = process.env.VUE_APP_ALOSSURL
Vue.prototype.webSocketUrl = process.env.VUE_APP_SOCKETURL

Vue.use(ElementUI);

import common from "./util/comment"
Vue.prototype.common = common;
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
 