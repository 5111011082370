const comment = {
	// 证件类型
	InvoiceOptions: [
		{
			value: 1,
			label: "组织机构代码证"
		},
		{
			value: 2,
			label: "税务登记证"
		},
		{
			value: 3,
			label: "统一社会信用代码"
		},
	],
	// 证件类型
	getInvoiceOptionsType(res) {
		let stage = parseInt(res)
		let arr = ''
		switch (stage) {
			case 1:
				arr = '组织机构代码证'
				break;
			case 2:
				arr = '税务登记证'
				break;
			case 3:
				arr = '统一社会信用代码'
				break;
		}
		return arr
	},
	// 发票类型
	BillType: [
		{
			label: "普通发票",
			value: 1,
		},
		{
			label: "增值税专用发票",
			value: 2,
		},
	],

	// 发票类型
	getBillType(res) {
		let stage = parseInt(res)
		let arr = ''
		switch (stage) {
			case 1:
				arr = '普通发票'
				break;
			case 2:
				arr = '增值税专用发票'
				break;
		}
		return arr
	},
	// 我的订单 订单状态
	myOrdreState: [
		{
			lable: "全部订单",
			value: "0"
		},
		{
			lable: "待发货",
			value: "10"
		},
		{
			lable: "待收货",
			value: "20"
		},
		{
			lable: "待评价",
			value: "30"
		},
		{
			lable: "已完成",
			value: "40"
		}
	],
	// 我的订单 订单状态
	myOrdreAllState: [
		{
			lable: "待支付",
			value: 0
		},
		{
			lable: "待发货",
			value: 10
		},
		{
			lable: "待收货",
			value: 20
		},
		{
			lable: "待评价",
			value: 30
		},
		{
			lable: "已完成",
			value: 40
		},
		{
			lable: "已取消",
			value: 50
		},
		{
			lable: "已关闭",
			value: 60
		}
	],
	// 0=待支付，10=待发货，20=待收货，30=待评价，40=已完成，50=已取消，60=已关闭
	myOrdreStateobj: {
		all: '',
		shipped: 10,
		received: 20,
		evaluated: 30,
		Completed: 40,
		Canceled: 50,
		Closed: 60,
	},
	// 退款订单 订单状态	
	refundOrderState: [
		{
			lable: "全部",
			value: '-1'
		},
		{
			lable: "申请中",
			value: '0'
		},
		{
			lable: "已退款",
			value: '10'
		},
		{
			lable: "已拒绝",
			value: '20'
		},
		{
			lable: "已取消",
			value: '30'
		},
	],
	//售后订单状态：0=申请中，10=已退款，20=已拒绝，30=已取消
	refundOrdreStateobj: {
		Applying: 0,
		Refunded: 10,
		Rejected: 20,
		Canceled: 30,
	},
	// 售后 订单状态
	refundOrdreAllState: [
		{
			lable: "申请中",
			value: 0
		},
		{
			lable: "已退款",
			value: 10
		},
		{
			lable: "已拒绝",
			value: 20
		},
		{
			lable: "已取消",
			value: 30
		}
	],
}
Vue.prototype.comment = comment;
export default comment
