
import router from "../router/index";
const util = {
	// 手机号码中段星号展示
	phoneEncryption(e) {
		return e.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
	},
	// 时间戳转换
	formatDate(date, fmt) {
		//1、获取年份
		if (/(y+)/.test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
		}
		//2、获取
		let o = {
			'M+': date.getMonth() + 1,
			'd+': date.getDate(),
			'h+': date.getHours(),
			'm+': date.getMinutes(),
			's+': date.getSeconds()
		};
		for (let k in o) {
			if (new RegExp(`(${k})`).test(fmt)) {
				let str = o[k] + '';
				fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str));
			}
		}
		return fmt;
	},

	padLeftZero(str) {
		return ('00' + str).substr(str.length);
	},

	goHome() {
		router.replace({
			name: 'index',
		})
	},
	goBack() {
		router.back()
	},

}
Vue.prototype.util = util;