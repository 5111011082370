<template>
  <div class="container">
    <div class="nav">
      <div class="nav_left cursor" @click="toDetail(5)">
        <img src="@/assets/img/login_text.png" alt class="login" />
      </div>
      <div class="nav_right">
        <div class="nav_right_item">
          <el-popover v-if="userphone" placement="bottom" width="150" trigger="click">
            <div class="layout cursor" @click="layout">退出登录</div>
            <div class="tis cursor btn_hover_text_color" slot="reference">欢迎光临，{{ userphone }}</div>
          </el-popover>
          <div class="tis cursor btn_hover_text_color" v-else>
            欢迎光临，请先
            <span @click="toDetail(4)" class="text_blod">登录/注册</span>
          </div>
          <div class="personal cursor btn_hover_text_color" @click="toDetail(3)">个人中心</div>
        </div>
        <div class="nav_right_item nav_right_bottom">
          <div>
            <div class="searchBox">
              <el-select
                slot="prepend"
                v-model="searchType"
                placeholder="请选择"
                class="search_select"
              >
                <el-option
                  v-for="item in Classification"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input placeholder="请输入关键字进行搜索..." class="search_input" v-model="searchText"></el-input>

              <div class="btn_search solid_btn_hover_background" @click="toDetail(0)">
                <img class="btn_search_icon" src="@/assets/img/icon_search.png" alt />
              </div>
            </div>
          </div>
          <div>
            <el-button
              class="btn_myorder solid_btn_hover_background"
              icon="el-icon-tickets"
              @click="toDetail(1)"
            >我的订单</el-button>
          </div>
          <div>
            <el-button
              class="btn_myorder RefundOrder solid_btn_hover_background"
              icon="el-icon-tickets"
              @click="toDetail(2)"
            >退款订单</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtitle"
      :visible.sync="dialogVisible"
      width="500px"
      :append-to-body="true"
      :destroy-on-close="true"
      center
      :lock-scroll="false"
    >
      <!-- 未登录内容 -->
      <div>
        <div>您还未登录，需登录后才能购买哦~</div>
        <div class="dialog_footer">
          <div class="login_cancle_btn" @click="dialogVisible = false">取消</div>
          <div type="primary" class="login_btn">确定</div>
        </div>
      </div>
      <!-- 立即支付内容  -->
      <div class="pay_box">
        <!-- <img class="pay_img" src="@/assets/img/test1.jpg" alt="" /> -->
        <div class="pay_right">
          <div>
            支付金额：
            <span class="pay_price">¥55.20</span>
          </div>
          <div class="pay_right_text_bottom">请使用微信扫描二维码进行支付</div>
        </div>
      </div>
      <!-- 关闭支付弹窗提示内容  -->
      <div>
        <div>关闭支付后，可在“我的订单”内进行支付。</div>
        <div class="dialog_footer">
          <div class="login_cancle_btn" @click="dialogVisible = false">取消</div>
          <div type="primary" class="login_btn login_btn_pay">继续支付</div>
        </div>
      </div>
      <!-- 支付完成内容 -->
      <div class="pay_success_box">
        <img class="icon_pay_sucess" src="@/assets/img/icon_pay_sucess.png" alt />
        <div>恭喜您，支付已完成。</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "myheader",
  data() {
    return {
      userphone: "",
      dialogVisible: false, //弹窗是否展示
      dialogtitle: "修改手机号码", //当前弹窗的标题
      dialogType: 1, //弹窗类型
      searchType: "", //当前搜索选中的类型
      searchText: "",
      Classification: [],
      userList: [
        {
          name: "修改手机号",
          id: 1
        },
        {
          name: "修改登录密码",
          id: 2
        },
        {
          name: "退出登录",
          id: 3
        }
      ]
    };
  },

  methods: {
    // 页面跳转
    toDetail(e) {
      //0首页 1 我的订单  2 退款订单 3 个人中心 4 登录注册 5 从logo进入首页
      if (e != 0 && e != 5 && e != 4 && !this.userphone) {
        this.$message({
          type: "warning",
          message: "请登录"
        });
        return;
      }
      switch (e) {
        case 0:
          if (!this.searchText) {
            this.$message({
              type: "warning",
              message: "请输入关键字!"
            });
            return;
          }
          this.$emit("search", this.searchType, this.searchText);
          this.$router.push({
            name: "index",
            query: {
              searchText: this.searchText,
              searchType: this.searchType
            }
          });

          break;
        case 1:
          this.$router.push({ name: "myOrder" });
          break;
        case 2:
          this.$router.push({ name: "refundOrder" });
          break;
        case 3:
          this.$router.push({ name: "personalCenter" });
          break;
        case 4:
          this.$router.push({ name: "login" });
          break;
        case 5:
          this.$router.push({ name: "index" });
          break;
      }
    },
    userOperate(e) {
      this.dialogVisible = true;
      this.dialogType = e;
    },
    // 获取分类
    shopType() {
      this.http.shopType().then(res => {
        if (res.code == 200) {
          let item = {
            name: "全部分类",
            id: ""
          };
          res.data.list.unshift(item);
          this.Classification = res.data.list;
          this.searchType = this.searchType
            ? this.searchType
            : res.data.list[0].id;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getsearchtext(type, text) {
      this.searchText = text;
      this.searchType = type;
    },
    getuser() {
      let userInfo = sessionStorage.getItem("userInfo");
      this.userphone = userInfo ? JSON.parse(userInfo).phone : "";
      if (this.userphone) {
        this.userphone = this.util.phoneEncryption(this.userphone);
      }
    },
    layout() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      if (this.$route.name != "index" && this.$route.name != "ProductDetails") {
        this.$router.push({ name: "index" });
        history.pushState(null, null, document.URL);
        window.addEventListener(
          "popstate",
          function(e) {
            history.pushState(null, null, document.URL);
          },
          false
        );
      }
      this.$message({
        message: "已退出登录",
        type: "success"
      });
      this.getuser();
    }
  },
  mounted() {
    this.getuser();

    this.shopType();
  },
  watch: {}
};
</script>
<style scoped="scoped">
.layout {
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.container {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ee0707;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.nav {
  width: 1200px;
  height: 140px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placeholder {
  height: 140px;
}

.login {
  width: 250px;
}

.nav_right_item {
  display: flex;
  justify-content: flex-end;
  /* padding-right: 20px; */
  font-size: 16px;
  color: #333333;
}

.text_blod {
  font-weight: bold;
  cursor: pointer;
}

.nav_right_bottom {
  margin-top: 30px;
}

.tis {
  margin-right: 40px;
}

.btn_myorder {
  font-size: 15px;
  font-weight: 400;
  background: rgba(238, 7, 7, 1) !important;
  color: #ffffff !important;
  border: 1px solid #ee0707 !important;
  height: 33px;
  line-height: 33px !important;
  padding: 0;
  width: 120px;
  margin-left: 20px;
  border-radius: 0;
}

.RefundOrder {
  margin-left: 10px;
}

.btn_myorder:first {
  margin-left: 20px;
}

.btn_search {
  width: 90px;
  height: 100%;
  background: rgba(238, 7, 7, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  display: flex;
  border: 1px solid #ee0707 !important;
  height: 33px;
}

::v-deep .search_select input {
  height: 33px !important;
  width: 105px;
  border: none !important;
  background: none !important;
}

::v-deep .search_select .el-icon-arrow-up {
  line-height: normal !important;
}

::v-deep .search_input input {
  height: 33px !important;
  border: none !important;
  background: none !important;
  padding: 0 0 0 10px !important;
}

.NoneBorder {
  border: none !important;
}

.btn_search_icon {
  width: 25px;
  height: 25px;
  margin: auto;
}

::v-deep .el-dropdown-menu__item,
.el-dropdown-menu__item {
  text-align: center;
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: rgba(238, 7, 7, 1);

  color: #ffffff;
}

::v-deep .el-select-dropdown__item,
.selected {
  color: #ee0707;
}

::v-deep.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: rgba(238, 7, 7, 1);
  color: #ffffff;
}

.personal {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}

/* 弹窗 */

.dialog_footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.login_btn {
  width: 60px;
  height: 33px;
  background: rgba(238, 7, 7, 1);
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  border-radius: 5px;
  line-height: 33px;
  margin-left: 40px;
  text-align: center;
}

.login_cancle_btn {
  width: 60px;
  height: 33px;
  background: rgba(209, 209, 209, 1);
  border-radius: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  color: #333333;
}

.pay_box {
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.pay_img {
  width: 180px;
  height: 180px;
}

.pay_right {
  padding-top: 34px;
  padding-left: 20px;
}

.pay_price {
  font-size: 28px;
  font-weight: bold;
  color: #ee0707;
}

.pay_right_text_bottom {
  margin-top: 20px;
}

.login_btn_pay {
  width: 80px;
}

.icon_pay_sucess {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
}

.pay_success_box {
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
}
</style>
