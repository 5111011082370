import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLogin: sessionStorage.getItem('isLogin') ? sessionStorage.getItem('isLogin') : false,
		ProductId: sessionStorage.getItem('ProductId')
	},
	getters: {
		getisLogin(state) {
			return state.isLogin
		},
		getProductId(state) {
			return state.ProductId
		},
	},
	actions: {
		setisLogin({ commit }, data) {
			commit('setisLogin', data)
		},

		setProductId({ commit }, data) {
			commit('setProductId', data)
		},
	},
	mutations: {
		setisLogin(state, data) {
			state.isLogin = data
			sessionStorage.setItem('isLogin', data)
		},
		setProductId(state, data) {
			state.ProductId = data
			sessionStorage.setItem('ProductId', data)
		},

	},
	modules: {
	}
})
